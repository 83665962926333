// Place in this file all constants which do not need to be externally injected (like env vars).
export const swrConfig = {
  dedupingInterval: 3000,
  // onError: (error) => {
  //   if (error == null) return;
  //   if (error.status !== 403 && error.status !== 404) {
  //     Sentry.captureException(error);
  //   }
  // },
  // Some settings to reduce the number of requests:
  // errorRetryCount: 1,
  // shouldRetryOnError: false,
  // refreshWhenHidden: false,
  // refreshInterval: 0,
  // refreshWhenOffline: false,
  // revalidateOnFocus: false,
};

export const ENVIRONMENT_DATA_REFRESH_INTERVAL_MS = 30 * 1000; // 30s;
export const STORE_LOCAL_STORAGE_KEY = "lugia-store";
export const STORE_LOCAL_STORAGE_VERSION = 1;

// Change the following values to test sentry or logrocket locally.
export const USE_SENTRY_IN_DEVELOPMENT = false;
export const USE_LOGROCKET_IN_DEVELOPMENT = false;
export const USE_ANALYTICS_IN_DEVELOPMENT = false;

// Visitor Profile
export const VISITOR_PROFILE_NAMES_MAX_LENGTH = 22;
export const READY_PLAYER_ME_AVATAR_URL = "https://readyplayer.me/api/avatar/";

export const HCAPTCHA_SITE_KEY = "3583714c-98a2-48f6-84e5-1a193b440a5f";

// Photo
export const PHOTO_MAX_WIDTH = 960;
export const LOGO_MAX_WIDTH = 200;
export const LOGO_MAX_HEIGHT = 100;
export const LOGO_SCREEN_OFFSET = 25;
export const LOGO_HEIGHT = 24;

// Links
export const GITBOOK_LUGIA_PAGE =
  "https://app.gitbook.com/o/V9IIQ3Cw10PlDcbzN32h/s/qyrASQADZu9oQKqZVpWm/";

export const LOCALSTORAGE_RECENT_ENV = "devRecentUrls";
export const LOCALSTORAGE_VISITOR_PROMPT = "visitorPrompt";

// Pointer Settings
export const POINTER_LOCK_MS_DELAY = 150;

// Activate extra logs
export const DEFAULT_LOG_SETTINGS = {
  GENERIC: true,
  PANELS_LAYOUT: false,
  USER_FLOW: true,
  CONTROLS_SETUP: false,
  WEBRTC: false,
  MESSAGE_IN: true,
  MESSAGE_IN_VERBOSE: false,
  MESSAGE_OUT: true,
  WS: false,
  MOUSE: false,
  KEYBOARD: false,
  "VOICE/VIDEO": true,
  IFRAME: true,
  POINTER_LOCK: false,
  VIDEOPLAY: false,
};

export const DEFAULT_FEATURE_FLAGS = {
  SCREEN_SHARE: false,
} as const;

export type FeatureFlag = keyof typeof DEFAULT_FEATURE_FLAGS;

// Voice Chat
export const DEFAULT_voiceMaxConnections = 6;
export const DEFAULT_voiceMaxClearCount = 4;
export const DEFAULT_chatBackend = "vonage";
export const DEFAULT_voiceConnectDistance = 20;
export const DEFAULT_twilioSignalingEuRegion = "de1";
export const DEFAULT_twilioRegion = "gll";

// Video Panel
export const endedEventDurationTolerance = 0.9; // milliseconds
export const curtainDownPositionSeconds = 2; // seconds

/**
 * This is the list of domains that will be mapped to the corresponding environment.
 */
export const mapDomainToEnvironment: Map<
  string,
  {
    envSlug: string;
    orgSlug?: string;
  }
> = new Map([
  [
    "journeenext--pr-283-support-custom-domains-kh85ff1r.web.app",
    {
      envSlug: "adonistest2",
    },
  ],
  [
    "hyperconfigurator.metahype.com",
    {
      envSlug: "hyperconfigurator",
    },
  ],
  [
    "pre.metahype.com",
    {
      envSlug: "hyperconfigurator",
    },
  ],
  [
    "trinityworld.cartier.com",
    {
      envSlug: "cartiertrinity",
    },
  ],
  [
    "mss-immersive-poc.apostrophy.com",
    {
      envSlug: "mss-immersive-poc",
    },
  ],
]);
