import { useReflow } from "../../common/hooks/ui";
import HintUiNormal from "./Hint.ui.normal";
import HintUiSmall from "./Hint.ui.small";

export type Props = {
  visible?: boolean;
  onClose?: () => void;
  content: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  noAnimation?: boolean;
  isLow?: boolean;
  dismissible?: boolean;
  expandable?: boolean;
};

const HintUi: React.FC<Props> = (props) => {
  return useReflow({
    normal: <HintUiNormal {...props} />,
    small: <HintUiSmall {...props} />,
  });
};

export default HintUi;
