import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { useCurrentChatSettings } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { isMobile } from "../../common/constants/flags.constant";
import usePrevious from "../../common/hooks/usePrevious";
import { unrealToCSSRGBColor } from "../../common/util/color";
import Hide from "../../componentsLibrary/atoms/Hide";
import { SocialPanelSubPageName } from "../_layout/panels.types";
import { useText } from "../language/language.hook";
import { SearchableProfile } from "../profile/lib/profileTypes";
import SocialUi from "./Social.ui";
import SocialTabs from "./SocialTabs";
import { parsePlayerKey } from "./socialUtils";
import Assistant from "./subpages/assistant/Assistant";
import TextChat from "./subpages/chat/TextChat";
import TextChatInput from "./subpages/chat/TextChatInput";
import PlayerList from "./subpages/players/PlayerList";
import PlayerListControlBar from "./subpages/players/PlayerListControlBar";
import PlayerProfileLogic from "./subpages/players/playerProfile/PlayerProfile.logic";
import useSearchPlayerList from "./subpages/players/useSearchPlayerList";

export type TabData =
  | {
      key: SocialPanelSubPageName;
      showFade: boolean;
      content: ReactNode;
      name?: never;
      hiddenFromTabs: boolean;
      backgroundColor?: string;
      drawer?: ReactNode;
    }
  | {
      key: SocialPanelSubPageName;
      showFade: boolean;
      content: ReactNode;
      name: string;
      hiddenFromTabs?: never;
      backgroundColor?: string;
      drawer?: ReactNode;
    };

export function parsePlayerExternalId(subpage: SocialPanelSubPageName): {
  playerId: number;
  roomId: string;
} | null {
  const parts = subpage.split("/");
  if (parts.length < 3) {
    return null;
  }
  return parsePlayerKey(parts[2]);
}

type Props = {
  visible?: boolean;
};

const SocialLogic: React.FC<Props> = ({ visible }) => {
  const closePanel = useStore((s) => s.layout.closePanel);
  const openPanel = useStore((s) => s.layout.openPanel);

  const { environment } = useEnvironmentContext();
  const hasSmartChats = environment.smartChats.length;
  const defaultSubpage = hasSmartChats ? "social/assistant" : "social/chat";
  const subpage = useStore(
    (s) => s.layout.panels.social.subpage ?? defaultSubpage
  );

  const nearbyPlayers = useStore((s) => s.gameConnection.nearbyPlayers);
  const playerRefreshIntervalId = useRef<number | null>(null);
  const getPlayerByKey = useStore((s) => s.gameConnection.getPlayerByKey);
  const { findManyPlayers } = useSearchPlayerList();
  const setAllPlayers = useStore((s) => s.gameConnection.setAllPlayers);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const t = useText();
  const isConferenceInitialized = useStore((s) =>
    s.videoConference.isConferenceInitialized()
  );
  const { textChatOnDesktop, textChatOnMobile } = useCurrentChatSettings();
  const { playerId } = useMemo(() => {
    const result = parsePlayerExternalId(subpage);
    if (!result) return { playerId: null, roomId: null };
    return result;
  }, [subpage]);

  let playerProfile: SearchableProfile | null = null;
  if (playerId && roomId) {
    playerProfile = getPlayerByKey(playerId, roomId);
  }

  const refreshPlayers = useCallback(async () => {
    if (!roomId) return;
    const playerIds = nearbyPlayers.map((p) => p.playerId);
    const allProfiles = await findManyPlayers({ roomId, playerIds });
    if (allProfiles) {
      setAllPlayers(allProfiles.map((p) => p.document));
    }
  }, [roomId, findManyPlayers, setAllPlayers, nearbyPlayers]);

  useEffect(() => {
    if (!playerRefreshIntervalId.current) {
      playerRefreshIntervalId.current = window.setInterval(
        refreshPlayers,
        5000
      );
    }
    return () => {
      if (playerRefreshIntervalId.current)
        window.clearInterval(playerRefreshIntervalId.current);
      playerRefreshIntervalId.current = null;
    };
  }, [refreshPlayers]);

  const [previouslySelectedTab, setPreviouslySelectedTab] = useState<
    SocialPanelSubPageName | undefined
  >(undefined);
  const previousSubPage = usePrevious(subpage);
  useEffect(() => {
    if (previousSubPage === subpage) {
      return;
    }
    if (previousSubPage?.includes("social/playerProfile")) {
      setPreviouslySelectedTab(undefined);
    } else if (previousSubPage) {
      setPreviouslySelectedTab(previousSubPage);
    }
  }, [previousSubPage, subpage]);

  const data: TabData[] = [
    {
      key: "social/players",
      name: t("social_people"),
      content: <PlayerList />,
      showFade: false,
      drawer: (
        <Hide unMount hide={!isConferenceInitialized}>
          <PlayerListControlBar />
        </Hide>
      ),
    },
    {
      key: "social/playerProfile/:playerId",
      hiddenFromTabs: true,
      backgroundColor:
        playerProfile?.avatarColor &&
        unrealToCSSRGBColor(playerProfile.avatarColor),
      content: (
        <PlayerProfileLogic
          playerProfile={playerProfile}
          onPrevious={() => {
            openPanel(previouslySelectedTab || "social/players");
          }}
        />
      ),
      showFade: false,
    },
  ];

  const setCurrentTab = (tab: SocialPanelSubPageName) => {
    openPanel(tab);
  };

  if (textChatOnDesktop || (textChatOnMobile && isMobile))
    data.unshift({
      key: "social/chat",
      name: t("social_chat"),
      showFade: true,
      content: <TextChat visible={visible} />,
      drawer: <TextChatInput />,
    });

  if (hasSmartChats) {
    data.unshift({
      key: "social/assistant",
      name: t("social_assistant"),
      showFade: true,
      content: <Assistant />,
      drawer: <TextChatInput isAssistant />,
    });
  }

  const selectedTab = data.find((it) => {
    if (it.key === subpage) {
      return true;
    }
    return it.key.includes("social/playerProfile");
  });

  const tabs = (
    <SocialTabs
      data={data}
      previouslySelectedTab={previouslySelectedTab}
      currentTab={subpage}
      setCurrentTab={setCurrentTab}
    />
  );

  if (!selectedTab) {
    return null;
  }
  return (
    <SocialUi
      selectedTab={selectedTab}
      tabs={tabs}
      onClose={() => {
        closePanel("social");
      }}
    />
  );
};

export default SocialLogic;
