import { useEffect, useMemo } from "react";
import { useStore } from "../../app/store";
import { PanelName } from "../../domains/_layout/panels.types";

/**
 * TODO: Remove after refactoring the panels rendering system.
 * this fix was introduced to remove the settings panel, since
 * its configuration is ghost.
 */
const useToggleGhostPanel = (
  ghostPanel: PanelName,
  currentPanel: PanelName
) => {
  const visible = useStore((s) => s.layout.panels[currentPanel].visible);

  const openPanel = useStore((s) => s.layout.openPanel);
  const closePanel = useStore((s) => s.layout.closePanel);
  const ghostPanelVisibility = useStore(
    (s) => s.layout.panels[ghostPanel].visible
  );
  const ghostPanelSubpanel = useStore(
    (s) => s.layout.panels[ghostPanel].subpage
  );

  const wasGhostPanelOpen = useMemo(
    () => Boolean(ghostPanelVisibility),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (wasGhostPanelOpen && visible) {
      closePanel(ghostPanel);
    }

    return () => {
      if (wasGhostPanelOpen && !visible) {
        if (ghostPanelSubpanel) {
          openPanel(ghostPanelSubpanel);
        } else {
          openPanel(ghostPanel);
        }
      }
    };
  }, [
    closePanel,
    openPanel,
    visible,
    ghostPanelSubpanel,
    wasGhostPanelOpen,
    ghostPanel,
  ]);
};

const useToggleGhostPanels = (currentPanel: PanelName) => {
  useToggleGhostPanel("settings", currentPanel);
  useToggleGhostPanel("social", currentPanel);
};

export default useToggleGhostPanels;
