import Button from "../../../componentsLibrary/atoms/Button";
import Icon from "../../../componentsLibrary/atoms/Icon";

type Props = {
  onClick?: () => void;
  dismissible?: boolean;
  expandable?: boolean;
};

const HintButton: React.FC<Props> = ({ onClick, dismissible, expandable }) => {
  return (
    <>
      {expandable && (
        <Button.Secondary circular onClick={onClick}>
          <Icon.Chevron size="18px" rotate="90deg" />
        </Button.Secondary>
      )}
      {dismissible && (
        <Button.Secondary circular onClick={onClick}>
          <Icon.Cross size="12px" />
        </Button.Secondary>
      )}
    </>
  );
};

export default HintButton;
