import styled, { css } from "styled-components";
import { steps } from "../../../app/style/theme";

export const IconContainer = styled.div<{
  $visible?: boolean;
  $noAnimation?: boolean;
}>`
  border-radius: ${(p) => p.theme.radiusBig};
  background-color: ${(p) => p.theme.colorAbove5};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.colorBelow1};
  flex-shrink: 0;

  ${(p) =>
    !p.$noAnimation &&
    css`
      ${p.$visible && "animation: hint-icon-enter 600ms ease-out"};
      opacity: ${p.$visible ? 1 : 0};
    `};

  @keyframes hint-icon-enter {
    0% {
      scale: 0;
    }

    50% {
      scale: 1.05;
    }
    100% {
      scale: 1;
    }
  }

  opacity: ${(p) => (p.$visible ? 1 : 0)};

  transition:
    opacity 0.3s,
    top 0.3s;
`;

export const ContentContainer = styled.div<{
  $visible?: boolean;
  $noAnimation?: boolean;
}>`
  ${(p) =>
    !p.$noAnimation &&
    css`
      ${p.$visible && "animation: hint-content-enter 600ms ease-out"};
      opacity: ${p.$visible ? 1 : 0};
    `};

  @keyframes hint-content-enter {
    0% {
      transform: translate(0px, 20px);
    }

    50% {
      transform: translate(0px, -1px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }

  transition:
    opacity 0.3s,
    top 0.3s;
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  > * + * {
    margin-inline-start: ${steps.spacing[2]};
  }
`;
