import { useCallback, useEffect, useMemo, useState } from "react";
import { useEnvironmentContext } from "../../app/EnvironmentDataProvider";
import { sendGameMessage } from "../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useCurrentMap } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import { useIsSmallScreen } from "../../common/hooks/ui";
import useToggleGhostPanels from "../../common/hooks/useToggleGhostPanels";
import { useText } from "../language/language.hook";
import MapUi from "./Map.ui";
import { useMapImageContext } from "./providers/MapImage.provider";

export type MapPoint = {
  name: string;
  spawnPoint?: number;
  title: string | null;
  label: string;
  image?: string;
  description: string;
  left: number;
  top: number;
  regionId?: string;
};

const MapLogic: React.FC<{ lastActiveRegion: string }> = ({
  lastActiveRegion,
}) => {
  const t = useText();
  const closePanel = useStore((s) => s.layout.closePanel);
  const { imgRatio, imgUrl, imgWidth, points } = useCurrentMap();
  const { mapImage } = useMapImageContext();
  const isSmallScreen = useIsSmallScreen();
  const {
    environment: { mapTitle, mapDescription, tabTitle, name },
  } = useEnvironmentContext();
  useToggleGhostPanels("map");

  const [showCard, setShowCard] = useState(true);
  const [previewMode, setPreviewMode] = useState(true);

  const getDefaultCard = useCallback(
    (mapTitle: string | null, mapDescription: string | null): MapPoint => {
      return {
        title: mapTitle || tabTitle,
        description: mapDescription || t("map_card_default_description"),
        spawnPoint: undefined,
        label: "",
        name: "",
        left: 0,
        top: 0,
      };
    },
    [t, tabTitle]
  );

  const activeRegionPoint = useMemo(
    () => points?.find((p) => p.regionId === lastActiveRegion),
    [lastActiveRegion, points]
  );

  // The initial selected point is the active region point or the default card.
  const [selectedPoint, setSelectedPoint] = useState<MapPoint>(
    activeRegionPoint || getDefaultCard(mapTitle, mapDescription)
  );

  const resetSelection = useCallback(() => {
    setSelectedPoint(
      activeRegionPoint || getDefaultCard(mapTitle, mapDescription)
    );
  }, [getDefaultCard, mapDescription, mapTitle, activeRegionPoint]);

  const handleTeleport = useCallback(
    (mapPoint: MapPoint) => {
      if (mapPoint?.spawnPoint != null) {
        sendGameMessage({
          type: "TeleportPlayer",
          spawnPoint: mapPoint.spawnPoint,
        });
        closePanel("map");
      }
    },
    [closePanel]
  );

  const handlePointSelection = useCallback(
    (point: MapPoint) => {
      setShowCard(false);

      if (isSmallScreen) {
        setSelectedPoint(point);
        setShowCard(true);
        return;
      }

      window.setTimeout(() => {
        setSelectedPoint(point || null);
        setShowCard(true);
      }, 400);
    },
    [isSmallScreen]
  );

  const handleClose = useCallback(() => {
    closePanel("map");
  }, [closePanel]);

  const handleCloseCard = () => {
    setShowCard(false);
    setPreviewMode(true);
  };

  // Reset states when passing from small to normal screen size.
  useEffect(() => {
    setShowCard(!isSmallScreen);
    resetSelection();
  }, [isSmallScreen, resetSelection]);

  useEffect(() => {
    if (!lastActiveRegion) return;
    const regionPoint = points?.find((p) => p.regionId === lastActiveRegion);
    if (!regionPoint) return;

    setSelectedPoint(regionPoint);
  }, [lastActiveRegion, points]);

  return (
    <MapUi
      imgSrc={imgUrl || ""}
      imgRatio={imgRatio}
      imgWidth={imgWidth}
      preloadedImage={mapImage}
      points={points || []}
      onSelectPoint={handlePointSelection}
      onClose={handleClose}
      selectedPoint={selectedPoint}
      activeRegion={lastActiveRegion}
      showCard={showCard}
      onCloseCard={handleCloseCard}
      onTeleport={handleTeleport}
      previewMode={previewMode}
      setPreviewMode={setPreviewMode}
      title={
        mapTitle ||
        tabTitle ||
        t("map_card_default_title", { environmentName: name })
      }
      description={mapDescription || t("map_card_default_description")}
    />
  );
};

export default MapLogic;
