import { GyaradosSDK } from "@journee-live/gyarados";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { useStore } from "../../../../app/store";
import { AssistantChatChannel } from "./AssistantTypes";

/** Gets the smart chat panel state. */
export const useAssistantChatChannel = (): AssistantChatChannel => {
  const { environment } = useEnvironmentContext();
  const activeChannel = useStore((s) => s.textChat.activeChannel);
  const currentChannel = useStore((s) =>
    activeChannel
      ? s.textChat.channels[activeChannel]
      : s.textChat.channels.public
  );

  const result: AssistantChatChannel = {
    ...currentChannel,
    fixedMessages: [],
  };

  if (result.type === "smartChat") {
    const chatData = environment.smartChats?.find(
      (chat) => chat.slug === activeChannel
    );
    result.thread = result.thread || [];
    result.name =
      (chatData
        ? (chatData.visualSettings as GyaradosSDK.VisualSettingsDto).name
        : activeChannel) || "";
    result.lastTyping = currentChannel?.lastTyping;
  }

  return result;
};
