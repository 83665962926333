import { useEffect, useState } from "react";
import { css, styled, useTheme } from "styled-components";
import { useEnvironmentContext } from "../../../../app/EnvironmentDataProvider";
import { useStore } from "../../../../app/store";
import { useIsLandscape, useIsSmallScreen } from "../../../../common/hooks/ui";
import { getImageUrl } from "../../../../common/util/gyarados.util";
import FadeIn from "../../../../componentsLibrary/atoms/FadeIn";
import Icon from "../../../../componentsLibrary/atoms/Icon";
import Image from "../../../../componentsLibrary/atoms/Image";
import Space from "../../../../componentsLibrary/atoms/Space";
import Typo from "../../../../componentsLibrary/atoms/Typo";
import { useText } from "../../../language/language.hook";
import { Wrapper } from "../chat/TextChat";
import TextChatThread from "../chat/TextChatThread";
import { useDeleteMessage } from "../chat/textChatHooks";
import { useAssistantChatChannel } from "./AssistantHooks";

const AvatarMargin = styled.div<{
  $isSmallscreenLandscape: boolean;
}>`
  ${(p) =>
    p.$isSmallscreenLandscape
      ? css`
          padding-right: 5%;
          padding-left: 5%;
          margin-right: max(var(--safe-margin-right), 64px);
          margin-left: max(var(--safe-margin-left), 64px);
        `
      : css`
          padding-left: 18px;
          padding-right: 18px;
        `};
`;

const AvatarContainer = styled.div`
  width: 78px;
  height: 78px;
  border: 2px solid ${(p) => p.theme.colorAbove1};
  background-clip: padding-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const DefaultAvatar = styled.div`
  width: 78px;
  height: 78px;
  background-color: ${(p) => p.theme.colorAbove5};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Assistant: React.FC = () => {
  const t = useText();
  const theme = useTheme();
  const isModerating = useStore((s) => s.textChat.isModerating);
  const setIsModerating = useStore((s) => s.textChat.setIsModerating);
  const isModerator = useStore((s) => s.session.isModerator());
  const isAdmin = useStore((s) => s.session.isAdmin());
  const isSmallScreen = useIsSmallScreen();
  const isLandscape = useIsLandscape();
  const canModerate = isModerator || isAdmin;
  const { type, thread, fixedMessages, lastTyping, name, slug } =
    useAssistantChatChannel();
  const { deletedMessages, handleMessageDelete } = useDeleteMessage();
  const [isTyping, setIsTyping] = useState(false);
  const { environment } = useEnvironmentContext();
  const smartChat = environment.smartChats?.find((chat) => chat.slug === slug);
  const imageSrc = getImageUrl(smartChat?.avatarImage, 80, 80) ?? "";
  // Activating the typing for 5 seconds each time we get a new typing event.
  // This relies on the unreal game sending "npcTyping" every few seconds (< 5).
  useEffect(() => {
    let timer: number | undefined;
    const typing = type === "smartChat" && Boolean(lastTyping);
    setIsTyping(typing);

    if (typing) {
      timer = window.setTimeout(() => {
        setIsTyping(false);
      }, 5000);
    } else if (timer) {
      clearTimeout(timer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [lastTyping, type]);

  // Clear typing effect after every new message
  useEffect(() => {
    setIsTyping(false);
  }, [thread]);

  return (
    <Wrapper $isSmallScreen={isSmallScreen}>
      <AvatarMargin $isSmallscreenLandscape={isLandscape && isSmallScreen}>
        <FadeIn>
          <Space h={4} />
          <AvatarContainer>
            {imageSrc ? (
              <Image alt="" src={imageSrc} />
            ) : (
              <FadeIn>
                <DefaultAvatar>
                  <Icon.Bust color={theme.colorBelow2} size="40px" />
                </DefaultAvatar>
              </FadeIn>
            )}
          </AvatarContainer>
          <Space h={2} />
          <Typo.Title fontFamily={theme.fontMain} weight={600}>
            {name === "Public" ? "" : name}
          </Typo.Title>
          <Space h={1} />
          <Typo.Body>{smartChat?.staticWelcomeMessageTemplate}</Typo.Body>
          <Space h={2} />
        </FadeIn>
      </AvatarMargin>
      <TextChatThread
        groupedMessageThread={thread}
        isModerationOn={isModerating && canModerate}
        onDelete={handleMessageDelete}
        deletedMessages={deletedMessages}
        fixedFirstMessagesGroup={fixedMessages}
        isTyping={isTyping}
        isAssistant
      />
      {canModerate && !isModerating && (
        <button
          onClick={() => {
            setIsModerating(true);
          }}
        >
          {t("chat_moderate")}
        </button>
      )}
    </Wrapper>
  );
};

export default Assistant;
