import { useEffect } from "react";
import { styled } from "styled-components";
import { useStore } from "../../../../app/store";
import { useIsSmallScreen } from "../../../../common/hooks/ui";
import { useText } from "../../../language/language.hook";
import TextChatThread from "./TextChatThread";
import { useDeleteMessage, useTextChatChannel } from "./textChatHooks";

// TODO: figure out this prop
// type Props = {
//   autoSubmitVirtualKeyboard?: boolean;
// };

export const Wrapper = styled.div<{ $isSmallScreen: boolean }>`
  padding-top: 60px;
  min-height: ${(p) => (p.$isSmallScreen ? "calc(100dvh - 150px)" : "400px")};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

type Props = {
  visible?: boolean;
};

const TextChat: React.FC<Props> = ({ visible }) => {
  const t = useText();
  const isModerating = useStore((s) => s.textChat.isModerating);
  const setIsModerating = useStore((s) => s.textChat.setIsModerating);
  const isModerator = useStore((s) => s.session.isModerator());
  const isAdmin = useStore((s) => s.session.isAdmin());
  const isSmallScreen = useIsSmallScreen();
  const canModerate = isModerator || isAdmin;
  const { thread, fixedMessages } = useTextChatChannel();
  const chatThread = useStore((s) => s.textChat.channels.public.thread);
  const setUnseenMessagesCount = useStore(
    (s) => s.textChat.setUnseenMessagesCount
  );
  const { deletedMessages, handleMessageDelete } = useDeleteMessage();

  useEffect(() => {
    if (visible) setUnseenMessagesCount(0);
  }, [chatThread, setUnseenMessagesCount, visible]);

  return (
    <Wrapper $isSmallScreen={isSmallScreen}>
      <TextChatThread
        groupedMessageThread={thread}
        isModerationOn={isModerating && canModerate}
        onDelete={handleMessageDelete}
        deletedMessages={deletedMessages}
        fixedFirstMessagesGroup={fixedMessages}
      />
      {canModerate && !isModerating && (
        <button
          onClick={() => {
            setIsModerating(true);
          }}
        >
          {t("chat_moderate")}
        </button>
      )}
    </Wrapper>
  );
};

export default TextChat;
