import { GyaradosSDK } from "@journee-live/gyarados";
import cuid from "cuid";
import sanitize from "sanitize-html";
import {
  LOGO_HEIGHT,
  LOGO_SCREEN_OFFSET,
  PHOTO_MAX_WIDTH,
} from "../../../common/constants/configs.constant";
import { logWarn } from "../../../common/util/logger";

export const ShareTargets = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  YAMMER: "yammer",
  COPY: "copy",
} as const;

export type ShareTarget = (typeof ShareTargets)[keyof typeof ShareTargets];

export type ShareTargetConfig = {
  url: string;
  link: string;
  text?: string;
  hashtags?: string;
  creator?: string;
};

const defaultShareData = {
  text: "The beautiful internet",
  twitterHashtags: "journee,immersiveweb",
  twitterCreator: "Journeelive",
};

export class MediaCaptureService {
  // See description here: https://dmnsgn.github.io/media-codecs/#AVC
  static readonly videoCodecs = [
    "avc1.4d002a",
    "avc1.4d0029",
    "avc1.4d0028",
    "avc1.42002a",
    "avc1.420029",
    "avc1.420028",
    "avc1.420032",
  ];
  static readonly audioCodecs = ["mp4a.40.2", "mp4a.40.5"];

  static readonly mime: Record<string, string> = {
    mp4: "video/mp4",
    webm: "video/webm",
    png: "image/png",
    "image/png": "png",
    "video/mp4": "mp4",
    "video/webm": "webm",
  };

  static readonly shareTargetConfigMap = new Map<
    ShareTarget,
    ShareTargetConfig
  >([
    [
      ShareTargets.FACEBOOK,
      {
        url: "https://www.facebook.com/sharer/sharer.php",
        link: "u",
      },
    ],
    [
      ShareTargets.TWITTER,
      {
        // Reference: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/parameter-reference1
        url: "https://twitter.com/intent/tweet",
        link: "url",
        text: "text",
        hashtags: "hashtags",
        creator: "via",
      },
    ],
    [
      ShareTargets.LINKEDIN,
      {
        url: "https://www.linkedin.com/sharing/share-offsite",
        link: "url",
      },
    ],
    [
      ShareTargets.YAMMER,
      {
        url: "https://www.yammer.com/messages/new?login=true&trk_event=yammer_share&group_id=null",
        link: "status",
      },
    ],
  ]);

  static getExtension(mimeType: string) {
    const mimeTypeWithoutCodecs = mimeType.split(";")[0];
    return this.mime[mimeTypeWithoutCodecs];
  }

  static getFileName(title?: string | null, extension = "png") {
    return `${sanitize(title ?? document.title)}.${extension}`;
  }

  static isVideo(mimeType: string) {
    return mimeType.startsWith("video");
  }

  static async getImageFromSource(src: string) {
    return new Promise<HTMLImageElement | undefined>((resolve) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => resolve(image);
      image.onerror = () => resolve(undefined);
      image.src = src;
    });
  }

  /**
   * A polyfill for `OffscreenCanvas` for old iOS versions (prior to 16.4).
   */
  static getTemporaryCanvas(width: number, height: number) {
    let canvas: HTMLCanvasElement | OffscreenCanvas;
    let context:
      | CanvasRenderingContext2D
      | OffscreenCanvasRenderingContext2D
      | null;
    if ("OffscreenCanvas" in window) {
      canvas = new OffscreenCanvas(width, height);
      context = canvas.getContext("2d");
    } else {
      canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      context = canvas.getContext("2d");
    }
    if (!context) throw Error("Canvas Context isn't supported.");
    return { canvas, context };
  }

  static getCanvasBlob(
    canvas: HTMLCanvasElement | OffscreenCanvas,
    mimeType = "image/png"
  ) {
    if (canvas instanceof HTMLCanvasElement) {
      return new Promise<Blob | undefined>((resolve) => {
        canvas.toBlob((blob) => resolve(blob ?? undefined), mimeType);
      });
    } else {
      return canvas.convertToBlob({ type: mimeType });
    }
  }

  static async getPhoto(
    video: HTMLVideoElement,
    logo: string | null | undefined,
    resize?: boolean,
    logoHeight?: number,
    mimeType = "image/png"
  ): Promise<Blob | undefined> {
    // We set the width to a maximum of PHOTO_MAX_WIDTH for reasons...
    // And scale the height to get the correct aspect ratio.
    const width = resize
      ? Math.min(PHOTO_MAX_WIDTH, video.videoWidth)
      : video.videoWidth;
    const height = resize
      ? (width * video.videoHeight) / video.videoWidth
      : video.videoHeight;

    const { canvas, context } = MediaCaptureService.getTemporaryCanvas(
      width,
      height
    );

    // Firefox on Android doesn't support this yet.
    // See: https://bugzilla.mozilla.org/show_bug.cgi?id=1526207
    context.imageSmoothingEnabled = true;
    context.imageSmoothingQuality = "high";
    context.drawImage(video, 0, 0, width, height);

    // Optionally draws the logo of the env on the picture for branding.
    if (logo) {
      const logoImage = await MediaCaptureService.getImageFromSource(logo);
      if (logoImage) {
        const dy = LOGO_SCREEN_OFFSET;
        const y = logoHeight ?? LOGO_HEIGHT;
        const x = logoImage.naturalWidth * (y / logoImage.naturalHeight);
        const imageBitmap = await createImageBitmap(logoImage, {
          resizeWidth: x,
          resizeHeight: y,
          resizeQuality: "high",
        });
        const dx = Math.floor((canvas.width - x) / 2);
        context.drawImage(imageBitmap, dx, dy, x, y);
      } else {
        logWarn("GENERIC", "Failed to download the logo to brand the photo");
      }
    }

    return MediaCaptureService.getCanvasBlob(canvas, mimeType);
  }

  static async getShareUrl(
    file: File,
    environmentId: string,
    visitorToken: string,
    extension = "png"
  ) {
    const fileName = cuid();
    const signResponse = await fetch(
      `${
        import.meta.env.VITE_GYARALESS_URL
      }/share/${environmentId}/${fileName}.${extension}/sign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${visitorToken}`,
        },
        body: JSON.stringify({}),
      }
    );
    const signBody = await signResponse.json();
    if (!signResponse.ok || !signBody.signedUrl) {
      throw new Error("Failed to get a signed url for the photo upload", {
        cause: signBody,
      });
    }

    const uploadResponse = await fetch(signBody.signedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": MediaCaptureService.getMimeType(extension),
      },
    });
    if (!uploadResponse.ok) {
      throw new Error("Failed to upload photo");
    }
    return `https://share.journee.live/s/${environmentId}/${fileName}`;
  }

  static getMimeType(extension: string) {
    switch (extension) {
      case "png":
        return "image/png";
      case "jpeg":
        return "image/jpeg";
      case "webp":
        return "image/webp";
      default:
        throw new Error("Unsupported extension");
    }
  }

  static shareOnSocialMedia(
    target: ShareTarget,
    link: string,
    environment: GyaradosSDK.EnvironmentConfigResponseDto,
    shareWindow?: Window | null
  ) {
    const params = MediaCaptureService.shareTargetConfigMap.get(target);
    if (!params) return;
    const url = new URL(params.url);
    url.searchParams.set(params.link, link);

    if (params.text) {
      url.searchParams.set(
        params.text,
        environment?.postText ?? defaultShareData.text
      );
    }
    if (params.hashtags) {
      url.searchParams.set(
        params.hashtags,
        // Twitter adds hashtag symbol automatically, so we remove it.
        environment?.twitterHashtags?.replaceAll("#", "") ??
          defaultShareData.twitterHashtags
      );
    }
    if (params.creator) {
      url.searchParams.set(
        params.creator,
        // Twitter adds @ symbol automatically, so we remove it.
        environment?.metaTwitterCreator?.replaceAll("@", "") ??
          defaultShareData.twitterCreator
      );
    }

    if (shareWindow) {
      shareWindow.location = url.toString();
    }
  }

  static isMp4Supported() {
    return MediaRecorder.isTypeSupported("video/mp4");
  }

  static isEncoderSupported() {
    return (
      "VideoEncoder" in window &&
      "AudioEncoder" in window &&
      "VideoFrame" in window &&
      "MediaStreamTrackProcessor" in window
    );
  }

  static async getSupportedVideoCodec(width = 1920, height = 1080) {
    for (const codec of this.videoCodecs) {
      const isSupported = await VideoEncoder.isConfigSupported({
        codec,
        width,
        height,
        framerate: 30,
      });
      if (isSupported.supported) {
        return codec;
      }
    }
  }

  static async getSupportedAudioCodec(
    numberOfChannels = 2,
    sampleRate = 48000
  ) {
    for (const codec of this.audioCodecs) {
      const isSupported = await AudioEncoder.isConfigSupported({
        codec,
        numberOfChannels,
        sampleRate,
      });
      if (isSupported.supported) {
        return codec;
      }
    }
  }
}
