import { useGetHintBySlug } from "../../app/hooks/gyarados.hook";
import { useStore } from "../../app/store";
import HintUi from "./Hint.ui";

type Props = {
  id?: string;
  visible?: boolean;
  // A custom content of the hint when triggered by Lugia
  content?: string;
};

const HintLogic: React.FC<Props> = ({ id, visible, content }) => {
  const data = useGetHintBySlug(id);
  const text = data?.content ?? content;
  const isInConference = useStore((state) =>
    state.videoConference.isConferenceInitialized()
  );
  const closePanel = useStore((s) => s.layout.closePanel);

  const handleClick = () => {
    if (!visible) return;
    closePanel("hint");
  };

  if (!text) return null;

  return (
    <HintUi
      visible={visible}
      content={text}
      isLow={isInConference}
      dismissible={data?.dismissable}
      onClick={handleClick}
    />
  );
};

export default HintLogic;
