import { useEffect, useState } from "react";
import { sendGameMessage } from "../../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../../../app/store";
import { useIsLandscape, useIsSmallScreen } from "../../../../common/hooks/ui";
import { useText } from "../../../language/language.hook";
import { useAssistantChatChannel } from "../assistant/AssistantHooks";
import TextChatInputUi from "./TextChatInputUi";
import { useTextChatChannel } from "./textChatHooks";

type Props = {
  isAssistant?: boolean;
};

const TextChatInput: React.FC<Props> = ({ isAssistant }) => {
  const t = useText();
  const addTextChatMessage = useStore((s) => s.textChat.addTextChatMessage);
  const isLandscape = useIsLandscape();
  const isSmallScreen = useIsSmallScreen();
  const [userHasJustSentMessage, setUserHasJustSentMessage] = useState(false);
  const { slug } = useTextChatChannel();
  const { slug: assistantSlug } = useAssistantChatChannel();
  const playerId = useStore((s) => s.gameConnection.playerId);
  const [value, setValue] = useState("");
  const finalSlug = isAssistant ? assistantSlug : slug;

  // Reset the state of userHasJustSentMessage after few seconds
  useEffect(() => {
    let timer: number | undefined;
    if (userHasJustSentMessage && !timer)
      timer = window.setTimeout(() => {
        setUserHasJustSentMessage(false);
      }, 3000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [userHasJustSentMessage]);

  const handleSendMessage = () => {
    if (value.trim() === "") {
      setValue("");
      return;
    }
    if (!value) return;
    setUserHasJustSentMessage(true);

    if (isAssistant && finalSlug) {
      sendGameMessage({
        type: "SmartChatUserPrompt",
        smartChatSlug: finalSlug,
        message: value,
      });
    } else {
      sendGameMessage({
        type: "SendChatMessage",
        content: value,
      });
    }
    if (finalSlug)
      addTextChatMessage(finalSlug, t("chat_me"), playerId || 0, value);
    setValue("");
  };

  return (
    <TextChatInputUi
      value={value}
      onChange={setValue}
      onSend={handleSendMessage}
      placeholder={t("chat_enter_message")}
      isSmallscreenLandscape={isLandscape && isSmallScreen}
    />
  );
};

export default TextChatInput;
