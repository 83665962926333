import { useCallback, useState } from "react";
import { sendGameMessage } from "../../../../app/gameConnection/webrtc/webRtcMessageHandlers";
import { useStore } from "../../../../app/store";
import { useText } from "../../../language/language.hook";
import { PublicTextChatChannel, TextChatMessage } from "./textChatTypes";

/** Gets the public chat panel state. */
export const useTextChatChannel = (): PublicTextChatChannel => {
  const t = useText();
  const publicChannel = useStore((s) => s.textChat.channels.public);
  const startTime = Date.now();

  const result: PublicTextChatChannel = {
    ...publicChannel,
    fixedMessages: [],
  };

  result.thread = result.thread || [];
  result.fixedMessages?.push({
    messages: [
      {
        content: t("chat_welcome"),
        sender: "Guide",
        senderId: -1,
        timestamp: startTime,
      },
    ],
    sender: "Guide",
    senderId: -1,
    timestamp: startTime,
  });

  return result;
};

export const useDeleteMessage = () => {
  const [deletedMessages, setDeletedMessages] = useState(new Set<string>());

  const handleMessageDelete = useCallback((message: TextChatMessage) => {
    if (message.messageId !== null && message.messageId !== undefined) {
      const id = `${message.senderId}_${message.messageId}`;
      setDeletedMessages((prev) => new Set(prev.add(id)));
      sendGameMessage({
        type: "DeleteMessage",
        messageId: message.messageId,
        userId: message.senderId,
      });
    }
  }, []);

  return { deletedMessages, handleMessageDelete };
};
