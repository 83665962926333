import { useStore } from "../../app/store";
import { useTextChatChannel } from "../social/subpages/chat/textChatHooks";
import TextChatPreviewUi from "./TextChatPreview.ui";

const TextChatPreviewLogic: React.FC = () => {
  const playerId = useStore((s) => s.gameConnection.playerId);
  const roomId = useStore((s) => s.gameConnection.roomId);
  const openPanel = useStore((s) => s.layout.openPanel);
  const { thread, unseenMessagesCount } = useTextChatChannel();
  const lastMessageGroup = thread.findLast(
    (message) =>
      !(
        message.senderId === playerId &&
        (message.roomId ? message.roomId === roomId : true)
      )
  );
  const onTextChatPreviewClick = () => openPanel("social/chat");
  const lastMessage =
    lastMessageGroup?.messages[lastMessageGroup.messages.length - 1];
  return unseenMessagesCount ? (
    <TextChatPreviewUi message={lastMessage} onClick={onTextChatPreviewClick} />
  ) : null;
};

export default TextChatPreviewLogic;
